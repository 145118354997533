.product {
  background: $white;
  margin: 16px;
  width: 200px;
  border-radius: 10px;
  .product-image {
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    max-height: 180px;
    img {
      cursor: zoom-in;
      width: 100%;
      //height: auto;
      //height: 50%;
      transition: transform 300ms ease-in;
      transform: scale(1);
      @media (min-width: 991px) {
        min-height: 200px;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }


  .product-name {
    margin-top: 10px;
    font-weight: normal;
    font-size: 13px;
    //line-height: 40px;
    margin-bottom: 10px;
    //text-overflow: ellipsis;
    line-height: 2em;
    height: 4em;
    overflow: hidden;
    background-color: $primary-orange;
  }

  .xxx {
    margin-top: 5px;
    margin-left: 3px;
    margin-right: 3px;
    line-height: 1em;
    height: 2em;
    overflow: hidden;
    //background-color: $primary-orange;
  }

  .yyy {
    font-size: 12px;
    padding: 0 5px;
    text-align: center;    
  }

  .product-price {
    font-size: 12px;
    font-weight: 700;
    //line-height: 22px;
    margin-top: 2px;
    margin-bottom: 5px;
    color: $gray-medium;
    &:before {
      content: "Rp ";
    }
    //background-color: $primary-green;
  }

  .product-price-strike {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 10px;
    color: $gray-light;
    &:before {
      content: "Rp ";
    }
  }

  .product-price-sold {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 10px;
    color: $red;  
    text-align: center; 
  } 

  .product-price-hidden {
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 16px;
    color: $gray-light;
  }

  .product-name,
  .product-price,
  .product-price-strike {
    padding: 0 5px;
    text-align: center;
  }

  .product-action {
    padding: 16px;
    button {
      width: 100%;
      transition: all 300ms ease-in;
      &.added {
        background: $primary-orange;
      }
    }
  }
  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

.stepper-input {
  display: flex;
  display: -webkit-flex;
  color: $gray-medium;
  max-width: 120px;
  margin: 0 auto;
  .increment,
  .decrement {
    height: 24px;
    width: 24px;
    border: 1px solid $gray-eighty;
    text-align: center;
    box-sizing: border-box;
    border-radius: 50%;
    text-decoration: none;
    color: inherit;
    font-size: 24px;
    line-height: 22px;
    -moz-user-select: none;
    -webkit-user-select: none;
    color: $white;
    border-color: $primary-green;
    background: lighten($primary-green, 15%);
    &:hover {
      color: $white;
      border-color: $primary-green;
    }
    &:active {
      color: $gray-eighty;
      border-color: $primary-green;
      background: lighten($white, 15%);
    }
  }
  .quantity {
    height: 24px;
    width: 48px;
    text-align: center;
    margin: 0 12px;
    border-radius: 2px;
    border: 0px solid $gray-eighty;
    &:focus {
      outline: none;
      border-color: $primary-green;
    }
  }
}
