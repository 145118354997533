footer {
  color: $gray-light;
  font-size: 14px;
  text-align: center;
  padding: 32px;
  background: $gray-dark-bg;
  height: 130px;
  strong {
    color: $gray-medium;
  }
  .footer-links {
    margin-bottom: 24px;
    a {
      margin: 0 8px;
      color: $gray-medium;
    }
  }
}
