@import './variables.sass';

.otherInfo {
  flex: 3; }

.lIWrapper, .cIWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    flex: 0 1 calc(50% - 1rem); } }

.contactInfo {
  margin-top: 2rem;
  padding-bottom: 1rem; }

.heading {
  display: flex;
  h3 {
    flex: 1; }
  h4 {
    flex: 1; }
  button {
    padding: 0 !important;
    background: transparent;
    color: $light-grey;
    border: none;
    line-height: 1;
    font-weight: 500;
    font-size: .9rem;
    text-transform: uppercase;
    transition: color .3s;
    cursor: pointer;
    &:hover {
      color: $black; } } }

.inputWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  word-break: break-word; }

p.inputData {
  padding: .5rem .5rem;
  color: $grey;
  margin: .7rem 0;
  font-size: .9rem;
  font-weight: 400;
  border-bottom: solid 2px transparent;
  background: #fff; }

p.inputData1 {
  padding: 0 0;
  color: $grey;
  margin: .7rem 0;
  font-size: .9rem;
  font-weight: 400;
  //border-bottom: solid 2px transparent
 }  //background: $light-grey

.cancelBtn {
  margin-left: 1rem; }

.receiptRight {
  font-size: .9rem;
  line-height: 1;
  font-weight: 500;
  font-family: roboto_mono, helvetica, sans-serif;
  text-align: right; }

h3 {
  font-size: 1rem;
  font-family: roboto_mono, helvetica, sans-serif; }

label {
  font-size: .9rem;
  line-height: 1;
  font-weight: 500;
  font-family: roboto_mono, helvetica, sans-serif; }

input, textarea {
  padding: .5rem 1rem;
  border: none;
  color: $grey;
  margin: .7rem 0;
  font-size: .9rem;
  font-weight: 400;
  border-bottom: solid 2px $light-grey;
  transition: color .3s, border-color .3s;
  background: #fff;
  &:active, &:focus {
    outline: none;
    color: $black;
    border-color: $black; } }


input {
  outline: none;
  box-sizing: border-box;
  width: 100%;
  &:invalid {
    box-shadow: none; } }

.basicInfo {
  flex: 1;
  margin-bottom: 40px; }

.profilePic {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  //background: #eee
  margin: 1rem auto 2rem auto; }

.nameWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h3 {
    text-align: center;
    font-size: 1.3rem; }
  p {
    margin-top: 0.5rem;
    text-align: center;
    font-size: 0.9rem;
    color: $black;
    font-family: roboto_mono, helvetica, sans-serif; } }


.consentWrapper {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	break-before: always;
	margin: 0 0 3em 0; }

.floatBlock {
  margin: 0 1.81em 0 0; }
