.wrapperSearchBar {
  width: 100%;
  max-width: 31.25rem;
  padding: 0px 10px 0px 10px;
  //background: #00ff00;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  //background: #ff0000;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.magnifyingIcon {
  width:24px;
  height:24px
}
