.modal-wrapper {
  display: none;
  background: rgba(0, 0, 0, 0.66);
  position: fixed;
  top: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  &.active {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
  }
  .modal {
    width: 100%;
    max-width: 532px;
    margin: 20px;
    background: #fff;
    position: relative;
    border-radius: 10px;
  }
  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 24px;
    //line-height: 26px;
    //border-radius: 20px 20px 20px 20px;
    //border-radius: 25px;
    background: $gray-medium;//transparent;
    color: $white; 
    &:hover {
      background: $gray-dark-bg;
    }
  }
}

.quick-view-details {
  padding: 24px;
  background: $gray-medium-bg;
  border-radius: 0 0 4px 4px;
  .product-price {
    font-size: 14px;
  }
  .product-price {
    color: $primary-green;
    font-weight: bold;
    font-size: 16px;
    float: right;
    &:before {
      content: "Rp ";
    }
  }
}
.quick-view-image {
  img {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    display: block;
    border-radius: 4px 4px 0 0;
  }
}

.email {
  display: none;
  background: rgba(0, 0, 0, 0.55);
  position: fixed;
  top: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  &.active {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
  }
  .modal {
    background: #DEDBDF;
    border-radius: 6px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.10), 0 6px 6px rgba(0,0,0,0.16);
    height: 400px;
    width: 300px;

  }
}

.to {
  //opacity: 0;
  //position: absolute;
  //transition: opacity 100ms cubic-bezier(0.4, 0.0, 1, 1);
}
.to-contents {
  //transform: scale(.55);
  //transform-origin: 0 0;
  //transition: transform 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
}
.top {
  background: $primary-green;
  display: flex;
  flex-direction: row;
  height: 70px;
  //transition: height 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
  width: 300px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.avatar-large {
  border-radius: 21px;
  height: 42px;
  margin-left: 12px;
  position: relative;
  top: 14px;
  width: 42px;
}
.name-large {
  color: #efd8ef;
  font-size: 16px;
  line-height: 70px;
  margin-left: 20px;
}
.x-touch {
  align-items: center;
  align-self: center;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  margin-left: auto;
  width: 50px;
}
.x {
  background: #FFFFFF;
  border-radius: 10px;
  height: 20px;
  position: relative;
  width: 20px;
}
.x-touch:hover .x {
  background: #DDDDDD;
}
.line1 {
  background: #000000;
  height: 12px;
  position: absolute;
  transform: translateX(9px) translateY(4px) rotate(45deg);
  width: 2px;
}
.line2 {
  background: #000000;
  height: 12px;
  position: absolute;
  transform: translateX(9px) translateY(4px) rotate(-45deg);
  width: 2px;
}
.bottom {
  background: #FFF;
  color:  #444247;
  font-size: 14px;
  height: 330px;
  padding-top: 5px;
  width: 300px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow-y: scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.bottom::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE and Edge */
.bottom {
    -ms-overflow-style: none;
}
.row {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 60px;
  padding-left: 10px;
}
.link {
  margin-left: 16px;
}
.link a {
  color:  #444247;
  text-decoration: none;
}
.link a:hover {
  color:  #777579;
}

.infoWrapper {
  display: flex;
  min-height: 40vh;
  flex-direction: column;
  padding: 1rem 0;
  //align-items: center;
  //justify-content: center;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 0 0;
    padding: 0;
}

.buttonShadow {
  width: 100%;
  height: 45px;
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  //text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #fff;
  background-color: $primary-green;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  }

.buttonShadow:hover {
  background-color: $primary-green;
  box-shadow: 0px 6px 10px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-2px);
}

.buttonShadowPlain {
  width: 100%;
  height: 45px;
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  //text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: $primary-green;
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  }

.buttonShadowPlain:hover {
  background-color: #ffffff;
  box-shadow: 0px 6px 10px rgba(46, 229, 157, 0.4);
  color: $primary-green;
  transform: translateY(-2px);
}

.loader {
  //display: none;
  //background: rgba(0, 0, 0, 0.66);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  display: flex;
  //flex-direction: column;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-justify-content: center;
}

.loaderLogistic {
  display: none;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  display: flex;
  flex-direction: column;
  display: -webkit-flex;
  align-items: center;
  padding: 1rem;
  border-radius: 8px;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-justify-content: center;
  h3 {
    font-size: 0.7rem;
  }
}

.loaderInfoWANotif {
  background: rgba(0, 0, 0, 0.1);
  position: relative;
  //top: 50%;
  //left: 50%;
  //transform: translate(-50%, -50%);
  //z-index: 100;
  display: flex;
  flex-direction: column;
  display: -webkit-flex;
  align-items: center;
  padding: 1rem;
  margin-top: 15px;
  border-radius: 8px;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-justify-content: center;
  h3 {
    font-size: 1rem;
    color: #777777;
    text-align: center;
  }
}
.product-namey {
  font-size: 12px;
}
