.progressbar-wrapper {
    //background: #fff;
    width: 100%;
    padding-top: 20px;
}

.progressbar{
  counter-reset: step;
}

.progressbar1{
  counter-reset: step;
}

.progressbar li{
  list-style: none;
  display: inline-block;
  width: 25%;
  position: relative;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
}

.progressbar1 li{
  list-style: none;
  display: inline-block;
  width: 50%;
  position: relative;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
}

.progressbar li:before, .progressbar1 li:before{
  content:counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  border: 2px solid #bebebe;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 30px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;
}

.progressbar li:after, .progressbar1 li:after{
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #ddd;
  top: 15px;
  left: -50%;
  z-index : -1;
}

.progressbar li:first-child:after , .progressbar1 li:first-child:after {
  content: none;
}
.progressbar li.active, .progressbar1 li.active {
  color: $primary-green;
}
.progressbar li.active:before, .progressbar1  li.active:before {
  border-color: $primary-green;
}
.progressbar li.active + li:after, .progressbar1 li.active + li:after {
  background-color: $primary-green;
}
